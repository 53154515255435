import BaseButton from "../components/BaseButton";

const Footer = () => {
  const openLinkedinTab = () => {
    window.open("https://www.linkedin.com/in/ariesmatteo/", "_blank");
  };

  const openGithubTab = () => {
    window.open("https://github.com/Smagia", "_blank");
  };

  return (
    <footer className="w-100">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-[600px]">
          <ul className="flex flex-col md:flex-row items-center gap-4">
            <li className="flex flex-col items-center">
              Email: <a href="mailto:ariesmatteo@gmail.com">ariesmatteo@gmail.com</a>
            </li>
            <li>
              <BaseButton
                data={{
                  text: "Linkedin Profile",
                  onClick: openLinkedinTab,
                }}
              />
            </li>
            <li>
              <BaseButton
                data={{
                  text: "Github Profile",
                  onClick: openGithubTab,
                }}
              />
            </li>
          </ul>
        </div>
        <div className="w-2/3 h-[1px] bg-black" />

        <p className="text-center">
          &copy; {new Date().getFullYear()} Matteo Aries. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
