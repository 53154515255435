function Navbar() {
  const items = [
    { id: "home", title: "Home", link: "#home" },
    { id: "about", title: "About", link: "#about" },
    { id: "skills", title: "Skills", link: "#skills" },
    { id: "contacts", title: "Contacts", link: "#contacts" },
  ];

  return (
    <div className="flex w-full justify-between items-center px-8">
      <p className="font-bold text-orange-600 text-2xl cursor-pointer">
        Matteo Aries
      </p>
      <ul className="flex gap-4 justify-center items-center text-xl invisible md:visible">
        {items.map((item) => (
          <li key={item.id} className="hover:scale-110 hover:font-semibold cursor-pointer">
            <a href={item.link}>{item.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Navbar;
