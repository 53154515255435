import { About } from "../views/About";
import ContactForm from "../views/Contact";
import Home from "../views/Home";
import Skills from "../views/Skills";

function HomePage() {
  return (
    <div className="flex flex-col justify-center items-center gap-32">
      <Home />
      <About />
      <Skills />
      <ContactForm />
    </div>
  );
}

export default HomePage;
