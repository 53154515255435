import React from "react";
import BaseButton from "../components/BaseButton";
import BaseContainer from "../containers/BaseContainer";

class Home extends React.Component<{}, { screenWidth: number }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  get() {
    return this.state.screenWidth;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleDownload = () => {
    const fileUrl = "./";

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "Aries_Matteo_Resume.pdf";

        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  render() {
    return (
      <BaseContainer
        id="home"
        className="h-[80vh] max-h-[1000px] bg-gray-200 flex justify-around items-center rounded-xl overflow-hidden"
      >
        <div className="flex flex-col gap-4 z-40">
          <h2 className="text-xl">I'm a </h2>
          <h1 className="text-2xl font-bold">
            Fullstack Developer
            <span className="text-orange-600 text-3xl">.</span>
          </h1>
          <BaseButton
            data={{ text: "Download Resume", onClick: this.handleDownload }}
          />
        </div>
        <div className="relative w-[500px] h-[500px] z-20">
          <div className="absolute bg-white rotate-45  w-[470px] h-[530px] rounded-xl"></div>
          {this.state.screenWidth > 900 ? (
            <img
              src="./profile.png"
              height="500px"
              width="500px"
              className="absolute"
              alt="Profile"
            />
          ) : null}
        </div>
      </BaseContainer>
    );
  }
}

export default Home;
