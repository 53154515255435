import "./App.css";
import HomePage from "./Pages/HomePage";
import Navbar from "./components/Navbar";
import Footer from "./views/Footer";

function App() {
  return (
    <div className="px-2 md:px-8 overflow-x-hidden max-w-[1500px] m-auto">
      <div className="p-8 flex flex-col gap-8">
        <Navbar />
        <div className="mb-32">
          <HomePage />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
