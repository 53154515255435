import SkillPoint, { SkillData } from "../components/SkillPoint";
import BaseContainer from "../containers/BaseContainer";

function Skills() {
  const skill: SkillData[] = [
    {
      title: "Java",
      description:
        "Using Spring Boot I'm able to build reliable architecture that satisfy requirements.",
      src: "./icons/java.png",
    },
    {
      title: "Angular",
      description:
        "Angular is one of my main technologies when we talk about front-end activities.",
      src: "./icons/angular.png",
    },
    {
      title: "Web Desing",
      description:
        "What is website witout a really good design? I'm focussed on delivering beautiful applications.",
      src: "./icons/web-d.png",
    },
    {
      title: "Architecture Solutions",
      description:
        "I'm able to provide different architecture solutions such as Microservices, MicroFrontend or Monolith.",
      src: "./icons/architecture.png",
    },
  ];
  return (
    <BaseContainer>
      <p
        className="px-8 text-2xl text-bold underline decoration-orange-600"
        id="skills"
      >
        My Skills
      </p>
      <div className="px-2 md:px-10 py-8 w-full flex flex-wrap justify-around items-center">
        {skill.map((item) => (
          <SkillPoint key={item.title} data={item} />
        ))}
      </div>
    </BaseContainer>
  );
}

export default Skills;
