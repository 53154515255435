import { useState } from "react";
import "../App.css";
import BaseContainer from "../containers/BaseContainer";

const ContactForm = () => {
  const [completed, setCompleted] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!name || message === "") {
      return;
    }

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "45f939a6-7454-41a6-9b3a-718154996e68",
          name: name,
          message: message,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setCompleted(true);
      }
    } catch (error: any) {
      console.error("Error submitting data:", error.message);
    }
  };

  return (
    <BaseContainer
      className="flex flex-col justify-center items-center rounded-xl"
    >
      <p className="text-2xl text-bold underline decoration-orange-600" id="contacts">
        Contact Me
      </p>
      {completed ? (
        <p className="text-xl font-bold text-main">Thanks for your Interest!</p>
      ) : (
        <div className="px-2 md:px-10 py-6 flex justify-around items-center w-full max-w-[700px]">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-start justify-center gap-4 w-full"
          >
            <label htmlFor="name">Enter your name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              className="w-full max-w-[700px] p-2 border-2 border-grey-900 outline-border-orange-600
          focus:border-orange-600 focus:outline-none rounded-md"
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={message}
              className="w-full max-w-[700px] h-[150px] p-2 border-2 border-grey-900 outline-border-orange-600
           focus:border-orange-600 focus:outline-none rounded-md text-wrap"
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="flex justify-center md:justify-end w-full">
              <button type="submit" className="btn-primary">
                Send Email
              </button>
            </div>
          </form>
        </div>
      )}
    </BaseContainer>
  );
};

export default ContactForm;
