import { createClient } from "contentful";
import { Enviroment } from "../enviroment";
import { useEffect, useState } from "react";

export interface JobExperience {
  companyName: string;
  jobTitle: string;
  startDate: Date;
  endDate: Date;
  description: string;
}

const client = createClient({
  space: Enviroment.spaceId,
  accessToken: Enviroment.accessToken,
});

function JobExperienceView() {
  const [data, setData] = useState([] as JobExperience[]);

  function formatDate(date: Date) {
    return new Date(date).toLocaleDateString();
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list: JobExperience[] = [];

        client
          .getEntries({
            content_type: "jobExperience",
            
          })
          .then(function (entries) {
            entries.items.forEach(function (entry) {
              list.push({ ...(entry.fields as any) });
            });
            list.sort((a, b) => {
              if(!a.endDate || !b.endDate) {
                return -1;
              }
              return a.endDate.getTime() - b.endDate.getTime()
            });
            setData(list);
          });
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <div  >
      <h1 className="text-2xl text-bold underline decoration-orange-600 pb-2">Job Experience</h1>
      <div className="h-fit md:h-[230px] px-2 md:px-8 py-2 overflow-auto">
      {data.map((entry) => (
        <div key={entry.companyName+' '+entry.jobTitle} className="flex flex-col justify-start items-start border-l-2 border-gray-300 w-full py-1 px-8 pb-6 relative">
          <div className="w-[15px] h-[15px] rounded-xl bg-orange-600 absolute -left-2 top-2"> </div>
          <div className="flex gap-2 justify-center">
            <p>{formatDate(entry.startDate)}</p>
            <p>-</p>
            <p>{entry.endDate ? formatDate(entry.endDate) : 'now'}</p>
          </div>
          <h2 className="text-xl font-bold text-center">{entry.companyName}</h2>
          <p className="line-clamp-4 text-center">{entry.jobTitle}</p>
        </div>
      ))}
      </div>
    </div>
  );
}

export default JobExperienceView;
