import React from "react";
import JobExperienceView from "../components/JobExperience";
import BaseContainer from "../containers/BaseContainer";

export class About extends React.Component<{}, { screenWidth: number }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  get() {
    return this.state.screenWidth;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <BaseContainer className="h-full flex flex-wrap justify-between items-center rounded-xl overflow-x-hidden">
        <div
          className="relative w-[300px] h-[300px] z-30 mb-16 md:mb-0"
          id="about"
        >
          <div className="absolute bg-gray-200 rotate-6 w-[200px] h-[200px] md:w-[300px] md:h-[300px] rounded-xl -left-5 md:left-0"></div>
          {this.state.screenWidth > 900 ? (
            <img
              src="./profile-1.png"
              height="525px"
              width="475px"
              className="absolute min-w-[250px] min-h-[250px] origin-center rounded-xl scale-90"
              alt="About"
            />
          ) : (
            <img
              src="./profile.png"
              height="500px"
              width="500px"
              className="absolute"
              alt="About"
            />
          )}
        </div>
        <div className="text-center md:text-start w-full max-w-[500px] flex flex-col pt-4 gap-8 z-40 justify-between">
          <div className="md:h-[250px] h-[400px]">
            <p className="text-2xl text-bold underline decoration-orange-600">
              About Me
            </p>
            <p>
              Hi, I'm a software engineer with a Master's degree in Computer
              Science. I love coding challenges and I am always learning new
              methods and technologies to improve my skills. I'm dedicated to
              creating software that meets business needs and clean code
              standards.
            </p>
          </div>
          <JobExperienceView />
        </div>
      </BaseContainer>
    );
  }
}
