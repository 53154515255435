export interface ButtonData {
  text: string;
  onClick: any;
}

interface ButtonDataProps {
  data: ButtonData;
}

const BaseButton = ({ data }: ButtonDataProps) => {
  return (
    <button
      className="btn-primary"
      onClick={data.onClick}
    >
      {data.text}
    </button>
  );
};

export default BaseButton;
