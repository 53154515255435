export interface SkillData {
  title: string;
  description: string;
  src: string;
}

interface SkillPointProps {
  data: SkillData;
}

function SkillPoint({ data }: SkillPointProps) {
  return (
    <div className="flex flex-col px-4 py-2 w-[300px] h-[300px] justify-start items-center font-mono gap-4">
      <img src={data.src} className="rounded-full p-4  h-[120px] w-[120px] bg-gray-200" alt="data.title"/>
      <h2 className="text-xl font-bold text-center">{data.title}</h2>
      <p  className="line-clamp-4 text-center">{data.description}</p>
    </div>
  );
}

export default SkillPoint;
